<template>
  <div
    class="wrapper"
    ref="wrapper"
  >
    <v-tooltip
      bottom
      open-delay="200"
      :disabled="!selectedName || !selectedName.length"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-autocomplete
          v-on="on"
          v-bind="attrs"
          autocomplete="no"
          :attach="attachEl"
          :clearable="clearable && !readonly"
          :dense="dense"
          :disable-lookup="readonly"
          :disabled="disabled"
          @click:clear="clearValue()"
          :flat="flat"
          :hide-details="hideDetails"
          hide-no-data
          item-color=""
          :item-text="itemText"
          :item-value="itemValue"
          :items="suggestions"
          :label="_label"
          :loading="computedLoading"
          :menu-props="computedMenuProps"
          :no-data-text="$t(computedLoading ? 't.Loading' : 't.NoResult')"
          no-filter
          :persistent-placeholder="persistentPlaceholder"
          :placeholder="placeholder"
          :readonly="readonly"
          :rounded="rounded"
          :rules="rules"
          :solo="solo"
          @update:search-input="setSearchText($event)"
          v-model="value"
          v-click-outside="clickOutside"
          @change="$emit('change', $event)"
          @keydown.esc="closeMenu"
          @mouseup="handleClick"
          @focus="handleFocus"
          ref="autocomplete"
        >
          <template v-slot:item="data">
            <v-list-item-content class="item-content">
              <document-picker-list-item-ref
                v-if="data.item.id"
                :item="data.item"
                class="clickable"
                :show-icon="showIcon"
                :show-detail="showDetail"
                :user-ref-props="{ isForSelection: true}"
              />
              <document-picker-list-item-fallback
                v-else
                :item="data.item"
              />
            </v-list-item-content>
          </template>
          <template
            v-if="search.resultCount > search.items.length"
            v-slot:append-item
          >
            <v-list-item
              @mousedown="search.retrieveNextPage()"
              class="clickable"
              ref="more"
            >
              <v-list-item-content>
                <document-picker-list-item-fallback :item="{name: $tc('t.MoreItems', search.resultCount - search.items.length), type: 'labels'}" />
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:append>
            <slot name="append" />
          </template>
          <template v-slot:append-outer>
            <slot name="append-outer" />
          </template>
        </v-autocomplete>
      </template>
      <span>{{selectedName}}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { DocSync } from '@/wasm/pkg'
import Search from '@/pages/search/controllers'

export default {
  components: {
    DocumentPickerListItemRef: () => import('@/components/document-picker-list-item-ref'),
    DocumentPickerListItemFallback: () => import('@/components/document-picker-list-item-fallback')
  },
  data () {
    const docSync = new DocSync()
    const obs = docSync.obs

    const search = new Search()
      .setScope(this.scope)
      .setFilters(this.filters)
      .chain(s => s.searchedIds.exclude(this.excludedItems.map(i => i?.id ? i.id : i)))
      .chain(s => s.searchedIds.include(this.includedItems.map(i => i?.id ? i.id : i)))
      .chain(s => s.searchedDocumentTypes.include(Array.isArray(this.documentTypes) ? this.documentTypes : [this.documentTypes]))
      .chain(s => s.groupContributorTypes.include(Array.isArray(this.groupContributorTypes) ? this.groupContributorTypes : [this.groupContributorTypes]))
      .setCanSendEmail(this.canSendEmail)
      .setCanSendSms(this.canSendSms)
      .setCanSendLetterDematerialized(this.canSendLetterDematerialized)
      .setCanSendMailevaLetter(this.canSendMailevaLetter)
      .setHasEmail(this.hasEmail)
      .setWorkItemTypeIsAccountAction(this.workItemTypeIsAccountAction)
      .setAllowEmptyParamsType(this.allowEmptyParamsType)
      .setAmtColsOnly(this.amtColsOnly)
      .setAudit(this.audit)
      .setAvgColsOnly(this.avgColsOnly)
      .setBoolColsOnly(this.boolColsOnly)
      .setCmpColsOnly(this.cmpColsOnly)
      .setDateColsOnly(this.dateColsOnly)
      .setNoColsAging(this.noColsAging)
      .setNoColsRelation(this.noColsRelation)
      .setSumColsOnly(this.sumColsOnly)

    const excludedIds = this.excludedItems.map(i => i?.id ? i.id : i)
    if (excludedIds.length) {
      search.searchedIds.exclude(excludedIds)
    }

    const includedIds = this.includedItems.map(i => i?.id ? i.id : i)
    if (includedIds.length) {
      search.searchedIds.include(includedIds)
    }

    return {
      attachEl: undefined,
      isAppendClick: false,
      docSync,
      http: this.$http().debounced(),
      isOutOfView: false,
      listItemHeight: 1,
      inputWidth: 1,
      menuIsOpen: false,
      innerSelectedDocument: null,
      obs,
      hasChange: false,
      observer: new ResizeObserver(this.eventHandler),
      search
    }
  },
  computed: {
    _label () {
      return this.hideLabel ? null : this.label
    },
    clickOutside () {
      return {
        handler: () => {
          this.closeMenu()
        },
        include: () => {
          const el = this.$refs?.more?.$el
          return el ? [el] : []
        }
      }
    },
    computedExcludedIds () {
      return this.excludedItems.map(i => i?.id ? i.id : i)
    },
    computedIncludedIds () {
      return this.includedItems.map(i => i?.id ? i.id : i)
    },
    computedLoading () {
      return this.search.loading || (this.obs.status === 1 /* && this.docSync.id */)
    },
    computedMenuProps: {
      get () {
        const top = typeof this.top === 'boolean' ? this.top : this.isOutOfView
        return {
          contentClass: 'background-plain',
          eager: this.attach !== false,
          maxHeight: 250, // Number(this.maxVisibleSuggestions) * this.listItemHeight + 16,
          minWidth: this.inputWidth,
          maxWidth: 2 * this.inputWidth,
          nudgeBottom: top ? 0 : 8,
          offsetY: true,
          top,
          value: this.menuIsOpen
        }
      }
    },
    selectedName () {
      return this.innerSelectedDocument?.name
    },
    suggestions () {
      const items = this.search.items

      if (this.innerSelectedDocument?.id && (!this.menuIsOpen ||
        (!items.some(i => i.id === this.innerSelectedDocument?.id) && !this.computedExcludedIds.some(id => id === this.innerSelectedDocument?.id) && !this.computedIncludedIds.some(id => id === this.innerSelectedDocument?.id)))) {
        const type = Array.isArray(this.documentTypes) ? this.documentTypes[0] : this.documentTypes
        const name = this.innerSelectedDocument[['account-contacts', 'users'].includes(type) ? 'fullname' : 'name']
        items.push({
          id: this.innerSelectedDocument?.id,
          type,
          name
        })
      }

      return items
    },
    value: {
      get () {
        return this.innerSelectedDocument?.id
      },
      set (val) {
        this.hasChange = true
        this.docSync.id = val
        this.$emit('update:selected-id', val)
      }
    }
  },
  methods: {
    setSearchText (v) {
      this.$emit('update:search-input', v)
      if (!this.hasChange) {
        this.$refs.autocomplete.$refs.menu.$refs.content?.scroll({ top: 0, behavior: 'instant' })
        this.search.searchText = v
      }
    },
    clearValue () {
      this.docSync.id = null
      this.innerSelectedDocument = { id: null }
      this.$emit('update:selected-document', null)
      this.search.clearText().execute()
    },
    resetAutocomplete () {
      this.$refs.autocomplete?.reset()
    },
    closeMenu () {
      this.menuIsOpen = false
      this.$refs.autocomplete?.blur()
    },
    async computeDimensions () {
      this.listItemHeight = await this.$waitFor(() => {
        const el = document.querySelector(`#${this.$refs.autocomplete?.computedOwns} .v-list-item:first-child`)
        return el && el.clientHeight
      })
      this.inputWidth = await this.$waitFor(() => this.$el.clientWidth)
    },
    openMenu () {
      this.menuIsOpen = true
      this.$nextTick(() => {
        this.$refs.autocomplete?.activateMenu()
      })
    },
    eventHandler (entries) {
      const rect = entries[entries.length - 1].contentRect
      this.isOutOfView = (this.$refs.wrapper.getBoundingClientRect().bottom + rect.bottom) > document.querySelector(this.bottomElementSelector).getBoundingClientRect().top
      this.$triggerResize()
    },
    focus () {
      this.handleClick()
      this.$refs.autocomplete?.focus()
      this.$refs.autocomplete?.$el.querySelector('input').click()
    },
    appendClick () {
      this.isAppendClick = true
      this.search.clearText().execute()
      this.openMenu()
      this.$nextTick(() => this.computeDimensions())
    },
    handleClick () {
      if (this.readonly) {
        return
      }
      this.hasChange = false
      this.openMenu()
      this.isAppendClick = false
      this.$nextTick(() => this.computeDimensions())
    },
    handleFocus () {
      if (!this.isAppendClick) {
        this.search.clearText().execute()
      }
    },
    validateDocType () {
      if (Array.isArray(this.documentTypes)) {
        const resolvedTypes = this.documentTypes.map(type => this.$cacheTypeFromString(type))
        if (!resolvedTypes.every((type, _, typeList) => type === typeList[0])) {
          throw new Error(`picker cannot resolve the document types to the same CacheType ["${this.documentTypes.join('", "')}"] match to [${resolvedTypes.join(', ')}]`)
        }
        this.docSync.cacheType = this.$cacheTypeFromString(this.documentTypes[0])
      } else {
        this.docSync.cacheType = this.$cacheTypeFromString(this.documentTypes)
      }
    }
  },
  created () {
    this.validateDocType()
  },
  async activated () {
    this.docSync.active = true
  },
  async mounted () {
    this.docSync.active = true
  },
  beforeDestroy () {
    this.search = undefined
    this.observer.disconnect()
  },
  deativated () {
    this.docSync.active = false
    this.observer.disconnect()
  },
  destroyed () {
    this.docSync.free()
  },
  watch: {
    async menuIsOpen (open) {
      if (!open || this.attach === false) { return }
      const el = typeof this.attach !== 'undefined' ? this.attach : await this.$waitFor(() => this.$refs.wrapper, 2000)
      this.attachEl = el
      const e = await this.$waitFor(() => this.$el.querySelector('div.v-menu__content'), 2000)
      if (e) {
        this.observer.observe(e)
      }
    },
    computedLoading (n) {
      this.$emit('update:loading', n)
    },
    documentTypes (n, o) {
      if (!this.lodash.isEqual(n, o)) {
        this.validateDocType()
        this.search
          .chain(s => s.searchedDocumentTypes.clear().include(Array.isArray(n) ? n : [n]))
          .execute()
      }
    },
    computedExcludedIds (items) {
      this.search.searchedIds.clear().exclude(items)
      if (this.menuIsOpen) {
        this.search.execute()
      }
    },
    computedIncludedIds (items) {
      this.search.searchedIds.clear().include(items)
      if (this.menuIsOpen) {
        this.search.execute()
      }
    },
    filters (n, o) {
      if (!this.lodash.isEqual(n, o)) {
        this.search.setFilters(n)
      }
      if (this.menuIsOpen) {
        this.search.execute()
      }
    },
    'obs.doc' (doc) {
      this.innerSelectedDocument = doc

      this.$emit('update:selected-document', doc)

      if (this.resetOnSelect && doc) {
        this.$nextTick(this.resetAutocomplete)
      }
    },
    scope (n, o) {
      if (!this.lodash.isEqual(n, o)) {
        this.search.setScope(n)
      }
      if (this.menuIsOpen) {
        this.search.execute()
      }
    },
    selectedId: {
      immediate: true,
      handler (n, o) {
        if (!this.lodash.isEqual(n, o)) {
          if (n === null) {
            this.innerSelectedDocument = { id: n }
          }
          this.docSync.id = n
        }
      }
    }
  },
  props: {
    amtColsOnly: {
      type: Boolean,
      default: undefined
    },
    audit: Object,
    avgColsOnly: {
      type: Boolean,
      default: undefined
    },
    boolColsOnly: {
      type: Boolean,
      default: undefined
    },
    cmpColsOnly: {
      type: Boolean,
      default: undefined
    },
    dateColsOnly: {
      type: Boolean,
      default: undefined
    },
    sumColsOnly: {
      type: Boolean,
      default: undefined
    },
    noColsRelation: {
      type: Boolean,
      default: undefined
    },
    noColsAging: {
      type: Boolean,
      default: undefined
    },
    allowEmptyParamsType: {
      type: Boolean,
      default: undefined
    },
    attach: {
      type: [String, Boolean, Object, HTMLElement],
      default: undefined
    },
    bottomElementSelector: {
      type: String,
      default: 'footer'
    },
    canSendEmail: {
      type: Boolean,
      default: undefined
    },
    canSendSms: {
      type: Boolean,
      default: undefined
    },
    canSendLetterDematerialized: {
      type: Boolean,
      default: undefined
    },
    canSendMailevaLetter: {
      type: Boolean,
      default: undefined
    },
    clearable: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    disabled: Boolean,
    documentTypes: {
      required: true,
      type: [Array, String]
    },
    excludedItems: {
      type: Array,
      default: () => []
    },
    includedItems: {
      type: Array,
      default: () => []
    },
    filters: Object,
    flat: Boolean,
    groupContributorTypes: {
      default: () => [],
      type: [Array, String]
    },
    hasEmail: {
      type: Boolean,
      default: undefined
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: [Boolean, String],
      default: 'auto'
    },
    itemText: {
      default: 'name'
    },
    itemValue: {
      default: 'id'
    },
    label: {
      type: String,
      default () { return this.$t('t.Search') }
    },
    loading: Boolean,
    maxVisibleSuggestions: {
      type: [Number, String],
      default: 5
    },
    maxSuggestions: {
      type: [Number, String],
      default: 10
    },
    persistentPlaceholder: Boolean,
    placeholder: String,
    readonly: Boolean,
    resetOnSelect: Boolean,
    rounded: Boolean,
    rules: Array,
    scope: Search.typeOfScope,
    selectedId: [Array, String, Number],
    selectedDocument: [Array, Object],
    solo: Boolean,
    top: {
      type: Boolean,
      default: undefined
    },
    showIcon: Boolean,
    showDetail: Boolean,
    workItemTypeIsAccountAction: Boolean
  }
}
</script>

<style lang="stylus" scoped>
.tooltip
  z-index 9999

>>>
  &.v-list, &.v-list-item, &.item-content
    min-width fit-content

.wrapper
  position relative
</style>
